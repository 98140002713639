import "./styles.scss"

import React from "react"
import { Link } from "gatsby"

const RegisterContent = () => {
  return (
    <section className="register-content">
      <div className="register-content__block">
        <div className="row">
          <div className="col-12">
            <p className="register-content__text">
              Zapraszamy do naszego gabinetu podologicznego. Umów swoją wizytę
              kontaktując się z nami telefonicznie lub dokonaj rezerwacji
              online!
            </p>
          </div>
        </div>
        <div className="row">
          <div className="col-md-6 col-12">
            <div className="register-content__left">
              <div className="register-content__img">
                <img
                  src={require("assets/images/register/register1.jpg").default}
                  alt=""
                />
              </div>
              <div className="register-content__info">
                <div className="register-content__left-phone">
                  <img src={require("assets/icons/phone.svg").default} alt="" />
                  <a href="tel:0048500301420">+48 500 301 420</a>
                </div>
                <p>Dostępny w godzinach otwarcia</p>
              </div>
            </div>
          </div>
          <div className="col-md-6 col-12">
            <div className="register-content__right">
              <div className="register-content__img">
                <img
                  src={require("assets/images/register/register2.jpg").default}
                  alt=""
                />
              </div>
              <div className="register-content__info">
                <div className="register-content__right-phone">
                  <Link to="/zespol/">Umów wizytę online</Link>
                </div>
                <p>Rejestracja online, dostępna całodobowo</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default RegisterContent
